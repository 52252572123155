@tailwind base;
@tailwind utilities;

:root {
    --giphy-black: #121212;
    --giphy-darkest-grey: #212121;
    --giphy-dark-grey: #2e2e2e;
    --giphy-dark-charcoal: #3e3e3e;
    --giphy-charcoal: #4a4a4a;
    --giphy-light-charcoal: #5c5c5c;
    --giphy-light-grey: #a6a6a6;
    --giphy-lightest-grey: #d8d8d8;
    --giphy-whitesmoke: #ececec;
    --giphy-white: #ffffff;
    /* primary */
    --giphy-blue: #00ccff;
    --giphy-green: #00ff99;
    --giphy-purple: #9933ff;
    --giphy-red: #ff6666;
    --giphy-yellow: #fff35c;
    /* secondary */
    --giphy-aqua: #00e6cc;
    --giphy-lightblue: #3191ff;
    --giphy-indigo: #6157ff;
    --giphy-pink: #e646b6;
}

body {
    background: var(--giphy-black);
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

button {
    padding: 0;
    background: transparent;
}

@keyframes bouncer {
    to {
        transform: scale(1.75) translateY(-20px);
    }
}

.loader {
    display: flex;
    align-items: center;
    height: 37px;
    padding-top: 15px;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    animation: pulse 0.8s ease-in-out 0s infinite alternate backwards;
}

.loader div {
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 37px 10px 10px 10px;
    position: relative;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    animation: bouncer cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.75s infinite alternate;
    &:nth-child(5n + 1) {
        background: var(--giphy-green);
        animation-delay: 0s;
    }
    &:nth-child(5n + 2) {
        background: var(--giphy-blue);
        animation-delay: calc(0s + (0.1s * 1));
    }
    &:nth-child(5n + 3) {
        background: var(--giphy-purple);
        animation-delay: calc(0s + (0.1s * 2));
    }
    &:nth-child(5n + 4) {
        background: var(--giphy-red);
        animation-delay: calc(0s + (0.1s * 3));
    }
    &:nth-child(5n + 5) {
        background: var(--giphy-yellow);
        animation-delay: calc(0s + (0.1s * 4));
    }
}

@layer utilities {
    .striped-background {
        background: repeating-linear-gradient(
            80deg,
            var(--giphy-dark-charcoal) 0px,
            var(--giphy-dark-charcoal) 1px,
            var(--giphy-darkest-grey) 1px,
            var(--giphy-darkest-grey) 7px
        );
        border: 1px solid var(--giphy-dark-charcoal);
    }
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
}

/*
Browser Standard Font Sizes
H1: 2em
H2: 1.5em
H3: 1.17em
H4: 1em
H5: 0.83em
H6: 0.67em
*/
h1,
h2,
h3,
h4,
h5,
h6 {
    @apply font-bold;
}

h1 {
    @apply text-giphyWhite text-2xl;
}
h2 {
    @apply text-giphyWhite text-xl;
}
h3 {
    @apply text-giphyWhite text-lg;
}
h4 {
    @apply text-giphyLightGrey text-base;
}
h5 {
    @apply text-giphyLightGrey text-sm;
}
h6 {
    @apply text-giphyLightGrey text-xs;
}
